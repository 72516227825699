import axios from '@axios'
import axiosDef from 'axios'
const APP_URL = process.env.VUE_APP_URL
/* const APP_GATEWAY = process.env.VUE_APP_GATEWAY
const APP_WALLET_CLIENTS = process.env.VUE_APP_WALLET_CLIENTS
const APP_WALLET_WALLETS = process.env.VUE_APP_WALLET_WALLETS
const TOKEN = process.env.VUE_APP_CURRENCYS_TOKEN */

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    
    fetchAgentsByBrandsReport(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${APP_URL}/transaction/report-players`, {params})
          .then(response => resolve(response))
          .catch(error => reject(error.response))
      })
    },
  },
  
}
